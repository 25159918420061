import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Button,
  CircularProgress,
  Paper,
  Snackbar,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { requestData } from 'services/APIClient';
import {
  MENU_SYNC_URL,
  MENU_UNSYNC_URL,
  USER_STORE_ONBOARD_URL,
  USER_STORE_PAYMENT_URL,
} from 'services/APIConstants';
import Interceptor from 'services/Interceptor';
import AuthToken, { isLoggedIn } from 'services/Token';

const Onboard = (props) => {
  const [openSnack, setOpenSnack] = useState(false);
  const [status, setStatus] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const { item } = props;
  const [message, setMessage] = useState('');
  const [isSyncing, setIsSyncing] = useState(false);

  const handleSubmit = async (itemObj) => {
    try {
      setStatus(true);
      const { uuid } = itemObj;
      const response = await requestData(
        USER_STORE_ONBOARD_URL(uuid.uuid),
        AuthToken(),
        isLoggedIn() ? Interceptor : null
      );
      window.location.replace(response);
      setStatus(false);
    } catch (err) {
      setStatus(false);
      console.log('An error encountered' + err);
      setMessage('An error encountered');
      setOpenSnack(true);
    }
  };

  const handlePayments = async (itemObj) => {
    try {
      setStatus(true);
      const { uuid } = itemObj;
      const response = await requestData(
        USER_STORE_PAYMENT_URL(uuid.uuid),
        AuthToken(),
        isLoggedIn() ? Interceptor : null
      );
      if (response.errors && response.errors.length > 0) {
        setPaymentStatus(response.errors[0]);
      } else {
        window.location.replace(response.data.checkoutLink);
      }
      setStatus(false);
    } catch (err) {
      setStatus(false);
      console.log('An error encountered' + err);
      setMessage('An error encountered');
      setOpenSnack(true);
    }
  };

  const handleMenuSync = async (itemObj) => {
    if (isSyncing) {
      setMessage('Sync is already in progress. Please wait.');
      setOpenSnack(true);
      return;
    }

    try {
      setIsSyncing(true);
      const { uuid } = itemObj;
      const response = await requestData(
        MENU_SYNC_URL(uuid.uuid),
        AuthToken(),
        isLoggedIn() ? Interceptor : null
      );

      if (response.error) {
        setMessage('Sync is already in progress. Please wait.');
      } else {
        setMessage(response.details.message);
        setIsSyncing(false);
      }
      setOpenSnack(true);
    } catch (err) {
      setMessage('An error encountered: ' + err);
      setOpenSnack(true);
      setIsSyncing(false);
    }
  };
  const handleMenuUnSync = async (itemObj) => {
    if (isSyncing) {
      setMessage('Sync is already in progress. Please wait.');
      setOpenSnack(true);
      return;
    }

    try {
      setIsSyncing(true);
      const { uuid } = itemObj;
      const response = await requestData(
        MENU_UNSYNC_URL(uuid.uuid),
        AuthToken(),
        isLoggedIn() ? Interceptor : null
      );

      if (response.error) {
        setMessage('Sync is already in progress. Please wait.');
      } else {
        setMessage(response.details.message);
        setIsSyncing(false);
      }
      setOpenSnack(true);
    } catch (err) {
      setMessage('An error encountered: ' + err);
      setOpenSnack(true);
      setIsSyncing(false);
    }
  };

  return (
    <>
      {status ? (
        <Paper variant="outlined">
          <Typography
            variant="h5"
            align="center"
            style={{ lineHeight: '50px' }}
          >
            <CircularProgress />
          </Typography>
          <br />
          <Typography variant="body2" align="center">
            Please wait
          </Typography>
        </Paper>
      ) : (
        <>
          <Button
            variant="contained"
            disableElevation
            disabled={!item.is_active}
            onClick={() => {
              handleSubmit(item);
            }}
          >
            Onboard/Update Service
          </Button>
          <br />
          <Typography align="center" variant="overline">
            {item.is_active
              ? 'Modify your existing delivery service or onboard a new one'
              : "Onboarding will be available after you've saved/registerd your store"}
          </Typography>
          <br />
          <Paper variant="outlined" style={{ margin: 10, padding: 10 }}>
            <Button
              variant="outlined"
              disableElevation
              disabled={!item.is_active}
              onClick={() => {
                handlePayments(item);
              }}
              endIcon={<OpenInNewIcon />}
            >
              Payment Settings
            </Button>
            <br />
            <Typography align="center" variant="overline">
              {paymentStatus
                ? paymentStatus
                : 'All Payments are handled via Stripe'}
            </Typography>
          </Paper>

          <Paper variant="outlined" style={{ margin: 10, padding: 10 }}>
            <Button
              variant="contained"
              disableElevation
              disabled={!item.is_active || isSyncing}
              onClick={() => {
                handleMenuSync(item);
              }}
            >
              Menu Sync
            </Button>
            <br />
            <Typography align="center" variant="overline">
              Sync with BEVZ Menu
            </Typography>
          </Paper>
          <Paper variant="outlined" style={{ margin: 10, padding: 10 }}>
            <Button
              variant="contained"
              disableElevation
              disabled={!item.is_active || isSyncing}
              onClick={() => {
                handleMenuUnSync(item);
              }}
            >
              Menu UnSync from BEVZ
            </Button>
            <br />
            <Typography align="center" variant="overline">
              UnSync zero stock item from BEVZ Menu
            </Typography>
          </Paper>
        </>
      )}

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnack}
        autoHideDuration={3000}
        onClose={() => {
          setOpenSnack(false);
        }}
        message={message}
      />
    </>
  );
};

export default Onboard;
